const Table = ({
  data,
  columns,
  onRowClicked = (rowData) => null,
  tableclassName = "w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400",
  tdClassName = "",
  thClassName = "",
  theadClassName = "text-xs text-white uppercase text-center bg-primary dark:bg-gray-700 dark:text-gray-400",
  scroll = true
}) => {
  return (
    <div className={`relative ${scroll?'overflow-x-auto':'overflow-x-hide'} rounded-sm`}>
      <table className={tableclassName}>
        <thead className={theadClassName}>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                scope="col"
                className={`${thClassName}`}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr
              key={rowIndex}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              onClick={() => onRowClicked(item)}
            >
              {columns.map((column, columnIndex) => (
                <td key={columnIndex} className={tdClassName}>
                  {column.render
                    ? column.render(item[column.key], item)
                    : item[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
