import React from "react";
import BasicCard from "../atoms/BasicCard";
import { Link, useNavigate } from "react-router-dom";

const DomainCard = ({ counts, title }) => {
  const navigate = useNavigate();
  let cardColor = {
    machines: { bg: "bg-blue-100", text: "text-blue-800" },
    domains: { bg: "bg-green-100", text: "text-green-800" },
    apps: { bg: "bg-yellow-100", text: "text-yellow-800" },
    appspaces: { bg: "bg-red-100", text: "text-red-800" },
    appnodes: { bg: "bg-fuchsia-100", text: "text-fuchsia-800" },
  };

  const handleNavigation = (label) => {
    if(label.toLowerCase()==='apps'){
      if(title.toLowerCase()==='bw5'){
        navigate(`/d/bw5applications`, { state: { ProductType: title } });
      }else{
        navigate(`/d/bw6applications`, { state: { ProductType: title } });
      }
    }else{
    navigate(`/d/${label.toLowerCase()}`, { state: { ProductType: title } });
    }
  };
  return (
    <BasicCard width={"flex-grow"} height={'h-64'}>
      <div className="flex flex-col">
        <div className="text-md font-medium mb-1 text-black">{title}</div>
        <div
          className={`grid ${counts.length===3?'grid-cols-2':'grid-cols-3'} gap-2 text-center`}
        >
          {counts.map((count, i) => {
            let bg = cardColor[count.label.toLowerCase()]
              ? cardColor[count.label.toLowerCase()].bg
              : "border-blue-100";
            let text = cardColor[count.label.toLowerCase()]
              ? cardColor[count.label.toLowerCase()].text
              : "text-blue-800";

            return (
              <div
                key={i}
                className={`p-4 rounded-lg ${bg} cursor-pointer`}
                onClick={() => handleNavigation(count.label)}
              >
                <Link to={`/d/${count.label.toLowerCase()}`}>
                  <div className={`text-xl font-bold ${text}`}>
                    {count.value}
                  </div>
                  <div className={`text-sm p-1 rounded-md ${text}`}>
                    {count.label}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </BasicCard>
  );
};

export default DomainCard;
