import { useDispatch } from "react-redux";
import { hideNotificaiton, showNotification } from "../components/organisms/notification/notificationSlice";

const useNotify = () => {
    const dispatch = useDispatch();
    const notify = (open, message , severity, duration) => dispatch(showNotification({open, message, severity, duration}));
    const stopNotify = () => dispatch(hideNotificaiton());
    return [
        notify,
        stopNotify
    ];
};

export default useNotify;