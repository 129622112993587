import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import TableSkelton from "../../../shared/components/modecules/TableSkelton";
import Table from "../../../shared/components/modecules/Table";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { JobService } from "../../../services";
import AppUtils from "../../../utils/AppUtils";
import { useState } from "react";
import { useNotify } from "../../../shared/hooks";
import { NotificationConstants } from "../../../utils/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomizationPanel from "../campaign/CustomizationPanel";
import { mapCustomization } from "../../../utils";
import GreenfieldIcon from "../../../assests/greenfield.png";
import TibcoIcon from "../../../assests/tibco_logo.png";
import BoomiIcon from "../../../assests/boomi_logo.png";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const jobService = new JobService();
const { NOTIFICATION_TYPE } = NotificationConstants;

const BlueprintList = ({ loading = false, data = [] }) => {
  const [notify] = useNotify();

  const [downloading, setDownloading] = useState(false);
  const [selectedBlueprint, setSelectedBlueprint] = useState({
    customizations: { function_mapping: [] },
  });
  const [openModal, setOpenModal] = useState(false);

  const onDownloadClick = async (rowData) => {
    if (downloading) return;
    setDownloading(true);
    setSelectedBlueprint(rowData);
    try {
      const { data, fileName } = await jobService.getJobOutput(rowData.job_id);
      AppUtils.downloadFile(data, fileName);
      notify(
        true,
        "Blueprint downloaded successfully!",
        NOTIFICATION_TYPE.success,
        5000
      );
    } catch (e) {
      notify(
        true,
        "Failed to download the blueprint. Please try again later.",
        NOTIFICATION_TYPE.error,
        5000
      );
      console.error(e);
    } finally {
      setDownloading(false);
      setSelectedBlueprint({});
    }
  };

  const onCusomizationClick = async (rowData) => {
    // setSelectedBlueprint(rowData);
    let custArr = [];
    try {
      custArr = mapCustomization(rowData.customization);
    } catch (e) {
      // customization could be null or error while parsing
    }
    setSelectedBlueprint({
      ...rowData,
      customizations: { function_mapping: custArr },
    });
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const columns = [
    {
      key: "bluePrint_name",
      title: "Name",
      render: (value) => value,
    },
    {
      key: "sourceType",
      title: "Source Type",
      render: (value) => value?.type,
    },
    {
      key: "sourceType",
      title: "Conversion",
      render: (value, rowData) =>
        (
          <div className="flex justify-center items-center gap-2">
            <img
              src={value?.type === "JSON" ? GreenfieldIcon : TibcoIcon}
              width={30}
              height={30}
            />
            <TrendingFlatIcon />
            <img
              src={
                rowData.targetType?.type === "JSON" ? GreenfieldIcon : BoomiIcon
              }
              width={30}
              height={30}
            />
          </div>
        ) ?? "-",
    },
    {
      key: "targetType",
      title: "Target Type",
      render: (value) => value?.type,
    },
    // {
    //   key: "targetPlatform",
    //   title: "Target Platform",
    //   render: (value) => value ?? "-",
    // },
    {
      key: "id",
      title: "Actions",
      render: (bpId, rowData) => (
        <div className="flex gap-1 justify-center min-w-12">
          <Tooltip
            title={
              downloading && bpId === selectedBlueprint.id
                ? "Downloading"
                : "Download"
            }
          >
            <IconButton
              className="text-primary"
              onClick={() => onDownloadClick(rowData)}
            >
              {downloading && bpId === selectedBlueprint.id ? (
                <RefreshIcon className="animate-spin" />
              ) : (
                <CloudDownloadOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={() => onCusomizationClick(rowData)}
            className="text-primary"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  if (loading) {
    return <TableSkelton rows={8} />;
  }
  return (
    <div className="">
      <Table
        data={data}
        tableclassName="w-full text-left border-separate border-spacing-y-[10px] sm:mt-2"
        tdClassName="px-5 py-3 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 s first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
        thClassName="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 bg-primary/10 dark:bg-darkmode-600 first:rounded-l-[0.6rem] last:rounded-r-[0.6rem]"
        theadClassName=""
        columns={columns}
      />
      <Dialog
        open={openModal}
        fullWidth
        maxWidth="md"
        onClose={handleCloseModal}
        PaperProps={{
          component: "form",
        }}
      >
        <DialogTitle>Preview Customizations</DialogTitle>
        <DialogContent>
          <DialogContentText className="pb-4">
            Blueprint customization details of the project.
          </DialogContentText>
          <CustomizationPanel
            customizations={selectedBlueprint?.customizations}
            setFormData={() => null}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BlueprintList;
