import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchAllAppNodesData = createAsyncThunk(
  "app/fetchAllAppNodesData",
  async (pageNumber) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/app_node_list`
    );
    return data;
  }
);

const getAppNodesListSlice = createSlice({
  name: "appNodes",
  initialState: { data: [], status: "idle", error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAppNodesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAppNodesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAllAppNodesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getAppNodesListSlice.reducer;
