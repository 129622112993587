
import StorageIcon from "@mui/icons-material/Storage";
import BasicModal from "../../../shared/components/molecules/BasicModal";
import ListItems from "../../../shared/components/molecules/ListItems";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CustomModel = ({ open, onClose, data = {}, columns, title }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <div className="flex gap-4 items-center justify-between">
      <div className="flex gap-4 items-center">
        <div className="w-12 h-12 border flex justify-center items-center rounded-md ">
          <StorageIcon />
        </div>
        <div>
          <p className=" font-semibold capitalize">{title}</p>
          <p className="text-gray-400"></p>
        </div>
        </div>
        <Tooltip title="Close" placement="bottom" arrow>
            <IconButton onClick={onClose}>
        <CloseIcon/>
        </IconButton>
        </Tooltip>
      </div>
      <div className="w-full border p-b-1 my-4" />
      <ListItems data={data} columns={columns} />
    </BasicModal>
  );
};

export default CustomModel;
