import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBW5AppDetailData } from "../../../shared/redux/getBW5AppDetailsDataSlice";
import Table from "../../../shared/components/modecules/Table";
import SummaryCard from "../../organisms/activity/activityDetails/SummaryCard";
import HorizontalChart from "../../../shared/components/molecules/HorizontalChart";
import { serviceInstanceColumns } from "./constants";
import CustomModel from "../CustomModel/customModel";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "text-xs text-white uppercase text-center bg-secondary dark:bg-gray-700 dark:text-gray-400",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "max-w-60 min-w-8 truncate text-ellipsis text-center text-sm py-2 px-2 align-middle border-b border-secondary-100",
};
const Bw5AppDetailes = () => {
  const { appId } = useParams();
  const data = useSelector((state) => state?.BW5appDetailData?.data);
  const countData = data.map((val)=>(
    { name: val?.ServiceInstance, 'Thread Count': val?.ThreadCount, 'Max Logfile Count':val?.MaxLogfileCount }
  ))
  const sizeData = data.map((val)=>(
    { name: val?.ServiceInstance, 'Initial Heap Size': val?.InitialHeapSize, 'Thread Stack Size':val?.ThreadStackSize }
  ))
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = (data) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const detailsTableColumns = [
    { title: "Application Name", key: "ApplicationName" },
    { title: "Service Instance", key: "ServiceInstance", 
      render: (value, rowData) => (
        <button
            className="hover:text-primary"
            onClick={() => handleOpenModal(rowData)}
          >
            {value}
          </button>
      )
    },
    { title: "Service Enabled", key: "ServiceEnabled",
    render: (value, rowData) => (
      value===1?'true':'false'
    ),
     },
    { title: "Process Archive", key: "ProcessArchive" },
    { title: "Status", key: "Status",
      render: (value, rowData)=>(
        <span
            className={`rounded-md w-16 p-1 text-center text-white ${
              value === "Running"
                ? "bg-green-500"
                : "bg-red-500"
            }`}
          >
            {value ? value : "Stopped"}
          </span>
      )
    },
    { title: "Deployment Status", key: "DeploymentStatus" },
    // { title: "Append Class Path", key: "AppendClassPath" },
    // { title: "Prepend Class Path", key: "PrependClassPath" },
    { title: "FtEnabled", key: "FtEnabled",
    render: (value, rowData) => (
      value===1?'true':'false'
    ),
     },
    // { title: "Home Directory", key: "HomeDirectory" }
  ];
  
  const CardItems = [
    {
      label: "Application Name",
      key: "ApplicationName",
      bg: "bg-white text-secondary",
    },
    {
      label: "Version",
      key: "Version",
      bg: "bg-white text-secondary",
    },
    {
      label: "Description",
      key: "Description",
      bg: "bg-white text-secondary",
    },
    {
      label: "Status",
      key: "AppStatus",
      bg: "bg-white text-secondary",
      formatter: (value, key)=>
        <div
          className={`rounded-md p-1 text-center text-white text-nowrap ${
            value === "Running"
              ? "bg-green-500"
              : value === "Partial Running" ?
                     "bg-blue-500 scroll-container"
                    : "bg-red-500"
          }`}
        >
          {value ? value : "Stopped"}
        </div>
    },
    {
      label: "Owner",
      key: "Owner",
      bg: "bg-white text-secondary",
    },
    {
      label: "Domain",
      key: "DomainName",
      bg: "bg-white text-secondary",
    },
    { label: "Machine", key: "ServiceMachine", bg: "bg-white text-secondary", },
  ];

  useEffect(() => {
    dispatch(fetchBW5AppDetailData(appId));
    const intervalId = setInterval(()=>{dispatch(fetchBW5AppDetailData(appId))}, 60000); // 300000ms = 5 minutes
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, appId]);

  return (
    <div className="flex gap-4 flex-col" style={{boxSizing:"border-box"}}>
    <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        <Link
          to="/d/bw5applications"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          &gt; BW5 Applications
        </Link>
        {data[0]?.ApplicationName && (
          <span className="ml-2 text-primary text-lg font-medium hover:text-primaryLight">
            &gt; {data[0].ApplicationName.charAt(0).toUpperCase() + data[0].ApplicationName.slice(1)}
          </span>
        )}
      </div>
      {/* <Bw5DetailsCard details={data} /> */}
      {/* <div className="flex gap-4"> */}
      <SummaryCard
        label="Summary"
        items={CardItems}
        jobDetail={data[0]}
        col={2}
        p="text-sm text-gray-400 p-2 flex border-gray-100  border-b my-1 gap-2 items-center"
      />
      {/* <HorizontalChart data={countData} xKey="name" yKey="Thread Count" zKey="Max Logfile Count"/>
      <HorizontalChart data={sizeData} xKey="name" yKey="Initial Heap Size" zKey="Thread Stack Size"/> */}
      {/* </div> */}
      

      <div className="bg-white rounded-lg p-2 shadow-md">
        <div className="h-72 rounded-md">
          <div className="h-72 max-h overflow-y-auto">
            <Table
              columns={detailsTableColumns}
              data={data}
              isStatic={true}
              customClasses={customClasses}
              tdClassName={customClasses.td}
              thClassName={customClasses.th}
              scroll={false}
              theadClassName = {customClasses.thead}
            />
          </div>
        </div>
      </div>
      <CustomModel
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedData}
        columns={serviceInstanceColumns}
        title={selectedData?.ServiceInstance}
      />
    </div>
  );
};

export default Bw5AppDetailes;
