import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/organisms/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllMachineData } from "../../../shared/redux/getMachineDataSlice";
import { useNavigate } from "react-router-dom";
import MachineModal from "./MachineModal";
import Filters from "../Filters/filters";
import { applyFilters } from "../Filters/helpers";
import DomainModal from "../Domains/DomainModal";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-center h-12 uppercase text-white sticky top-0 z-10",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "bg-white min-w-8 truncate text-ellipsis text-center text-sm py-4 px-6 align-middle border-b border-secondary-100",
};

const initialFilters = [
  {
    type: "DROPDOWN",
    key: "ServerName",
    placeholder: "Machine",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "DomainName",
    placeholder: "Domains",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "ProductType",
    placeholder: "Product",
    value: "",
  },
];

const Machines = () => {
  const location = useLocation();
  const { pathname, state } = location;
  const lastSlashIndex = pathname.lastIndexOf("/");
  const domainName =
    lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : null;

  const [filters, setFilters] = useState(initialFilters);
  const [dropdownValues, setDropdownValues] = useState({});

  const [rowData, setRowData] = useState([]);

  const data = useSelector((state) => state.machineData?.data);
  const domainData = useSelector((state) => state.domainData?.data);
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();
  const [selectedMachine, setSelectedMachine] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const navigate = useNavigate();

  const handleDomainClick = (domain) => {
    let selectedDomain = domainData.find(
      (obj) => obj.domainName === domain.domainName
    );
    setSelectedDomain(selectedDomain)
    setIsDomainModalOpen(true)
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDomainModalOpen(false)
  };
  const handleMachineClick = (selectedMachine) => {
    setSelectedMachine(selectedMachine);
    setIsModalOpen(true);
  };

  const handleApplicationClick = (row) => {
    if(row?.original?.ProductType==='BW5'){
      navigate(`/d/bw5applications`);
    }else{
      navigate(`/d/bw6applications`);
    }
  };
  useEffect(() => {
    if (state?.ProductType) {
      handleFilterChange(state.ProductType, 2);
    }
  }, [state, data]);

  const handleFilterChange = (value, index) => {
    const cloned = JSON.parse(JSON.stringify(filters));
    const obj = cloned[index];
    obj.value = value;
    setFilters(cloned);
  };

  const onFilterReset = () => {
    setFilters(initialFilters);
  };

  useEffect(() => {
    setRowData(data);
    const ServerName = new Set();
    const DomainName = new Set();
    const ProductType = new Set();
    data?.forEach((item) => {
      ServerName.add(item.ServerName);
      DomainName.add(item.DomainName);
      ProductType.add(item.ProductType)
    });
    setDropdownValues({ ServerName, DomainName, ProductType });
  }, [data]);

  useEffect(() => {
    const filteredData = applyFilters(filters, data);
    setRowData(filteredData);
    console.log("filter", filteredData)
  }, [filters]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Machine",
        accessor: "ServerName",
        Cell: ({ row }) => (
          <button onClick={() => handleMachineClick(row.original)}>
            {row.original.ServerName}
          </button>
        ),
      },
      {
        Header: "Enviroment Id",
        accessor: "EnvironmentId",
        
      },
      { Header: "OS", accessor: "OperatingSystem" },
      { Header: "Domain", accessor: "DomainName",
      Cell: ({ row }) => (
        <button onClick={() => handleDomainClick(row.original)}>
          {row.original.DomainName}
        </button>
      ),
       },
      {
        Header: "Application #",
        accessor: "ApplicationCount",
        Cell: ({ row }) => (
          <button onClick={() => handleApplicationClick(row)}>
            {row.original.ApplicationCount}
          </button>
        ),
      },
    ],
    []
  );

  const fetchData = useCallback((pageIndex) => {
    console.log("Async Data CALLED", pageIndex);
    dispatch(fetchAllMachineData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  return (
    <div className="flex flex-col py-6">
      {/* Breadcrumb navigation */}
      <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        {domainName && (
          <span className="ml-2 text-primary text-lg font-medium hover:text-primaryLight">
            &gt; {domainName.charAt(0).toUpperCase() + domainName.slice(1)}
          </span>
        )}
      </div>

      {/* Domain-related content */}
      <div className="self-start self my-6 p-0">
      <Filters
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterReset={onFilterReset}
        dropdownValues={dropdownValues}
      />
      </div>
      
      {/* Table container aligned to the right */}
      <div className="bg-white rounded-lg p-2 shadow-md">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
        {
          <Table
            columns={columns}
            data={rowData}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        }
      </div>
      </div>
      </div>
      <MachineModal
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedMachine}
      />
      <DomainModal
        open={isDomainModalOpen}
        onClose={handleCloseModal}
        data={selectedDomain}
      />
    </div>
  );
};

export default Machines;
