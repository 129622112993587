import { Button } from "@mui/material";
import Table from "../../../../shared/components/modecules/Table";
import { useGet } from "../../../../shared/hooks";
import { useEffect } from "react";
import { BlueprintService } from "../../../../services";
import TableSkelton from "../../../../shared/components/modecules/TableSkelton";
import BlueprintList from "../../blueprint/BlueprintList";

const blueprintService = new BlueprintService();

const BlueprintsOverview = () => {
  const { fetchData, data, loading } = useGet();

  useEffect(() => {
    fetchData(blueprintService.getBlueprints(1, 5));
  }, []);

  return <BlueprintList loading={loading} data={data?.records} />;
};

export default BlueprintsOverview;
