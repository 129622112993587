
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
const ConfigModel = ({ open, onClose, data = ''}) => {
  return (
    <Dialog fullWidth={true}  open={open} onClose={onClose} maxWidth='lg'>
    <DialogTitle className="flex justify-between">App Node Configuration 
    <Tooltip title="Close" placement="bottom" arrow>
            <IconButton onClick={onClose}>
    <CloseIcon/>
    </IconButton>
    </Tooltip>
    </DialogTitle>
      <DialogContent className="bg-black text-white px-20 py-10">
      <pre >
        <code>
            {`${typeof data==='string'&&data&&data!=null?data.replaceAll('^^', '\n'):'No values to display at this movement!!'}`}
        </code>
        </pre>
        </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default ConfigModel;
