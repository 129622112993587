import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGet } from "../../../shared/hooks";
import { BlueprintService } from "../../../services";
import BlueprintList from "./BlueprintList";
import CreateBlueprint from "./CreateBlueprint";

const blueprintService = new BlueprintService();

const Blueprint = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const { fetchData, data, loading } = useGet();
  const getBlueprints = async () => {
    await fetchData(blueprintService.getBlueprints(1, 50));
  };
  useEffect(() => {
    getBlueprints();
  }, []);

  const addBlueprint = () => {
    setIsAddModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsAddModalVisible(false);
  };

  const onBlueprintCreated = () => {
    getBlueprints();
  };

  return (
    <div className="mx-auto p-2 mt-1 px-6">
      <div className="flex justify-between items-center mb-4">
        <div className="text-4xl font-medium text-primary">Blueprints</div>

        <Button variant="contained" onClick={addBlueprint}>
          Create blueprint
        </Button>
      </div>{" "}
      <BlueprintList loading={loading} data={data?.records} />
      {isAddModalVisible && (
        <CreateBlueprint
          onClose={handleCloseModal}
          onBlueprintCreated={onBlueprintCreated}
        />
      )}
    </div>
  );
};

export default Blueprint;
