import { Card } from "@mui/material";
import React from "react";

const MetricsCard = ({ key, icon, data, title,bg }) => {
  return (
    // <div key={key} className="relative w-full max-w-sm pt-4 z-20">
    //   <div className="absolute left-4 -top-0 z-30">
    //     <div className="w-16 h-16 rounded-xl bg-gradient-to-br from-blue-600 to-blue-400 flex items-center justify-center shadow-lg">
    //       {icon}
    //     </div>
    //   </div>

    //   {/* Main card */}
    //   <Card className="relative w-full pt-4 pb-8 px-4 bg-white rounded-lg">
    //     <div className="flex flex-col items-center justify-center text-center">
    //       {/* Metric */}
    //       <h2 className="text-4xl font-bold text-orange-800 mb-2">
    //         {data ?? 'N/A'}
    //       </h2>
    //       <p className="text-orange-800">{title}</p>
    //     </div>
    //   </Card>
    // </div>
      <div>
        <div class="leading-none me-1 mb-2">
          <span className={bg + " avatar avatar-lg  border-[3px] avatar-rounded p-5 "}>
            {icon}
          </span>
        </div>
        <div>
          <span class="block mb-0 text-textmuted dark:text-textmuted/50 text-lg">
            {title}
          </span>
          <h4 class="font-medium text-2xl mb-0">{data ?? "N/A"}</h4>
        </div>
      </div>
  );
};

export default MetricsCard;
