import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";
// import { useSelector } from "react-redux";

export const fetchAllDomainData = createAsyncThunk(
  "domain/fetchAllDomainData",
  async (alerts, pageNumber) => {
    // const alerts = useSelector((state) => state.alertsData?.nodeDown);
    let { data } = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/domains_list`
    );
    return data.map((domain)=>(alerts.includes(`NodeDown~${domain?.ServerName}`)?{...domain, Status:'Stopped'}:domain));
  }
);

const getDomainDataSlice = createSlice({
  name: "domainData",
  initialState: { data: [], status: "idle", error: null, domains: [] },
  reducers: {
    setDomains: (state, action) => {
      state.data = state.data.map((domain)=>(action.payload.includes(`NodeDown~${domain?.ServerName}`)?{...domain, Status:'Stopped'}:domain));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDomainData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDomainData.fulfilled, (state, action) => {
        
        state.status = "succeeded";
        state.data = action.payload;
        state.domains = [...new Set(action.payload.map(obj => obj.DomainName))]
      })
      .addCase(fetchAllDomainData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { setDomains } = getDomainDataSlice.actions;
export default getDomainDataSlice.reducer;
