import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ComposedChart,
  Area,
  PieChart,
  Pie,
  Cell,
  Label,
} from "recharts";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";

const ActivitiesAndFlows = ({ data: flowData }) => {
  const [selectedFlow, setSelectedFlow] = useState("all");

  const formatFlowName = (name) => {
    return name.split("/").pop().replace(".process", "");
  };

  const formatActivityName = (name) => {
    return name.split(".").pop().replace("Activity", "");
  };

  const COLORS = useMemo(
    () => [
      "#002d9c",
      "#6929c4",
      "#005d5d",
      "#115f9a",
      "#1984c5",
      "#22a7f0",
      "#48b5c4",
      "#76c68f",
      "#a6d75b",
      "#c9e52f",
      "#d0ee11",
      "#f4f100",
    ],
    []
  );

  const { activityStats, flowStats, activityDistribution } = useMemo(() => {
    const activityCounts = {};
    const flowActivityCounts = {};
    const activityByFlow = {};
    const allActivityTypes = new Set();

    // First pass: collect all activity types
    flowData &&
      flowData.flows &&
      flowData.flows.forEach((flow) => {
        flow.components.forEach((component) => {
          allActivityTypes.add(component.activityType);
        });
      });

    // Second pass: build distribution data
    flowData &&
      flowData.flows &&
      flowData.flows.forEach((flow) => {
        flowActivityCounts[flow.name] = flow.components.length;
        activityByFlow[flow.name] = {};

        // Initialize all activity types with 0
        allActivityTypes.forEach((type) => {
          activityByFlow[flow.name][type] = 0;
        });

        // Count activities
        flow.components.forEach((component) => {
          if (selectedFlow === "all" || selectedFlow === flow.name) {
            activityCounts[component.activityType] =
              (activityCounts[component.activityType] || 0) + 1;
          }
          activityByFlow[flow.name][component.activityType]++;
        });
      });

    // Create distribution data for the radar chart
    const activityDistribution = Array.from(allActivityTypes).map(
      (activityType) => {
        const distribution = {
          activity: formatActivityName(activityType),
          fullName: activityType,
        };

        if (selectedFlow === "all") {
          // Calculate percentage for each flow
          Object.entries(activityByFlow).forEach(([flowName, activities]) => {
            const flowTotal = flowActivityCounts[flowName];
            distribution[formatFlowName(flowName)] =
              (activities[activityType] / flowTotal) * 100;
          });
        } else {
          // Calculate percentage for selected flow
          const flowTotal = flowActivityCounts[selectedFlow];
          distribution.value =
            (activityByFlow[selectedFlow][activityType] / flowTotal) * 100;
        }

        return distribution;
      }
    );

    const activityStats = Object.entries(activityCounts).map(
      ([key, value]) => ({
        key,
        value,
      })
    );

    const flowStats = Object.entries(flowActivityCounts).map(
      ([key, value]) => ({
        name: formatFlowName(key),
        value,
        fullName: key,
        color:
          COLORS[Object.keys(flowActivityCounts).indexOf(key) % COLORS.length],
      })
    );

    return { activityStats, flowStats, activityDistribution };
  }, [flowData, selectedFlow, COLORS]);

  const totalActivities = activityStats.reduce(
    (sum, item) => sum + item.value,
    0
  );
  const activitiesCount = totalActivities;

  const handleFlowChange = (event) => {
    setSelectedFlow(event.target.value);
  };

  // New visualization for activity distribution
  const renderActivityDistribution = () => {
    if (selectedFlow === "all") {
      // Radar chart for all flows
      return (
        <ResponsiveContainer width="100%" height={400}>
          <RadarChart outerRadius={150} data={activityDistribution}>
            <PolarGrid />
            <PolarAngleAxis
              dataKey="activity"
              tick={{ fill: "#666", fontSize: 12 }}
              tickLine={{ stroke: "#ccc" }}
            />
            <PolarRadiusAxis angle={30} domain={[0, 100]} />
            {flowStats.map((flow, index) => (
              <Radar
                key={flow.name}
                name={flow.name}
                dataKey={flow.name}
                stroke={COLORS[index]}
                fill={COLORS[index]}
                fillOpacity={0.3}
              />
            ))}
            <Tooltip
              formatter={(value) => [`${value.toFixed(1)}%`]}
              contentStyle={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                borderRadius: "6px",
                padding: "8px",
                border: "1px solid #ddd",
              }}
            />
          </RadarChart>
        </ResponsiveContainer>
      );
    } else {
      // Composed chart for single flow
      return (
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart data={activityDistribution}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="activity"
              angle={-45}
              textAnchor="end"
              height={70}
              interval={0}
            />
            <YAxis
              domain={[0, 100]}
              label={{
                value: "Percentage of Activities",
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle" },
              }}
            />
            <Tooltip
              formatter={(value) => [`${value.toFixed(1)}%`]}
              contentStyle={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                borderRadius: "6px",
                padding: "8px",
                border: "1px solid #ddd",
              }}
            />
            <Bar
              dataKey="value"
              fill="primary"
              shape={(props) => {
                const { x, y, width, height } = props;
                return (
                  <g>
                    <rect
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      fill="#000"
                    />
                    <rect
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      fill="url(#colorGradient)"
                      fillOpacity={0.3}
                    />
                  </g>
                );
              }}
            >
              <LabelList
                dataKey="value"
                position="top"
                formatter={(value) => `${value.toFixed(1)}%`}
              />
            </Bar>
            <Area
              type="monotone"
              dataKey="value"
              fill="#4ECDC4"
              stroke="#45B7D1"
              fillOpacity={0.1}
            />
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4ECDC4" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#45B7D1" stopOpacity={0} />
              </linearGradient>
            </defs>
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
  };

  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 100) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius - 40) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {value}{" "}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${(percent * 100).toFixed(0)}`}{" "}
        </text>
      </>
    );
  };
  const CustomLabel = ({ viewBox, value1, value2 }) => {
    const { cx, cy } = viewBox;
    return (
      <>
      <text
        x={cx}
        y={cy-10}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan alignmentBaseline="middle" fontSize="26">
          {value1}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 10}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="14">{value2}</tspan>
      </text>
      </>
    );
  };
  const renderChart = () => {
    return (
      <>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Pie
              data={flowStats}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={100}
              fill="#8884d8"
              legendType="square"
              lege
              label
              // label={renderCustomizedLabel}
            >
              {flowStats.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              <Label content={<CustomLabel value1={activitiesCount} value2="Activities"></CustomLabel>} position="center" />
            </Pie>
            <Legend verticalAlign="bottom" height={100} cy={10} />
          </PieChart>
        </ResponsiveContainer>
      </>
    );
  };

  if (!flowData || !flowData.flows) {
    return null;
  }

  return (
    <div className="w-full p-2">
      <div className="flex justify-between items-center mb-6">
        {/* <Typography variant="h6" component="h1">
          TIBCO Activity Analysis
        </Typography> */}
        <div className="flex items-center gap-4"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Flow Statistics Card */}
        <Card sx={{ height: 600, display: "flex", flexDirection: "column" }}>
          <CardContent>
            <Typography variant="h6" component="h2">
              Activities Across Flows
            </Typography>
          </CardContent>
          <CardContent sx={{ flexGrow: 1, p: 2, height: "100%" }}>
            <div className="h-full">{renderChart()}</div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h2">
              {selectedFlow === "all"
                ? "Activity Distribution Comparison Across Flows"
                : `Activity Distribution in ${formatFlowName(selectedFlow)}`}
            </Typography>
          </CardContent>
          <CardContent sx={{ p: 2 }}>
            {renderActivityDistribution()}
          </CardContent>
        </Card>
        {/* Activity Details Table Card */}
        <Card
          sx={{
            height: 600,
            display: "flex",
            flexDirection: "column",
            bgcolor: "#f1f5f9",
            boxShadow: 0,
          }}
        >
          <CardContent>
            <div class="flex items-center ">
              <div class="text-base font-medium truncate">
                <Typography variant="h6" component="h2">
                  Activity Details{" "}
                  {selectedFlow !== "all" &&
                    `- ${formatFlowName(selectedFlow)}`}
                </Typography>
              </div>
              <div class="flex items-center ml-auto text-primary">
                <FormControl className="w-60" size="small">
                  <InputLabel>Select Flow</InputLabel>
                  <Select
                    value={selectedFlow}
                    onChange={handleFlowChange}
                    label="Select Flow"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="all">All Flows</MenuItem>
                    {flowData.flows.map((flow) => (
                      <MenuItem key={flow.name} value={flow.name}>
                        {formatFlowName(flow.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </CardContent>
          <CardContent sx={{ flexGrow: 1, p: 0, overflow: "hidden" }}>
            <div className="h-full overflow-auto">
              <table className="w-full text-left border-separate border-spacing-y-[10px] sm:mt-2">
                {/* <thead className="sticky top-0 bg-white">
                  <tr>
                    <th className="px-4 py-3 text-left font-medium bg-gray-100 text-gray-700 border-b">
                      Activity Type
                    </th>
                    <th className="px-4 py-3 text-right font-medium bg-gray-100 text-gray-700 border-b">
                      Count
                    </th>
                    <th className="px-4 py-3 text-right font-medium bg-gray-100 text-gray-700 border-b">
                      Percentage
                    </th>
                  </tr>
                </thead> */}
                <tbody>
                  {activityStats.map((item) => (
                    <tr
                      key={item.key}
                      className="intro-x bg-white hover:bg-theme-2/20 even:bg-theme-1/20"
                    >
                      <td className="px-5 py-3 border-b dark:border-darkmode-300 shadow-none box w-40 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                        <div class="font-semibold text-slate-600">
                          {formatActivityName(item.key)}
                        </div>
                      </td>
                      <td className="text-center px-5 py-3 border-b dark:border-darkmode-300 shadow-none box w-40 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                        <div class="font-semibold text-slate-600">
                          {item.value}
                        </div>
                      </td>
                      <td className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                        <div className=" px-2 w-15 text-center py-1 text-sm font-semibold text-white rounded-full cursor-pointer bg-theme-2">
                          {((item.value / totalActivities) * 100).toFixed(1)}%
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/* <tr className="sticky bottom-0 bg-gray-100 font-semibold">
                    <td className="px-4 py-3 border-t">Total</td>
                    <td className="px-4 py-3 text-right text-blue-700 border-t">
                     
                    </td>
                    <td className="px-4 py-3 text-right text-blue-700 border-t">
                      100%
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* New full-width activity distribution card */}
    </div>
  );
};

export default ActivitiesAndFlows;
