import { useEffect } from "react";
import { ProjectService } from "../../../../services";
import { useGet } from "../../../../shared/hooks";
import CampaignList from "../../campaign/CampaignList";

const CampaignsOverview = ({ onRowViewClicked }) => {
  const { fetchData, data = [], loading } = useGet();

  useEffect(() => {
    fetchData(new ProjectService().getProjectsUrl());
  }, []);

  return (
    <CampaignList
      loading={loading}
      data={data?.records}
      onRowViewClicked={onRowViewClicked}
      hideActions={true}
    />
  );
};

export default CampaignsOverview;
