import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";

const NavigationButtons = ({
  activeStep = 0,
  totalStep,
  handleBack,
  handleNext,
  handleDone,
  isLoading,
  doneBtnLabel = "Done",
  continueBtnLabel = "Continue",
  backBtnLabel = "Back",
}) => {
  return (
    <div className="flex gap-2 justify-end p-2">
      <Button onClick={handleBack} disabled={activeStep <= 0 || isLoading}>
        {backBtnLabel}
      </Button>
      {activeStep === totalStep - 1 ? (
        <Button onClick={handleDone} variant="contained">
          {doneBtnLabel}
        </Button>
      ) : (
        <LoadingButton
          onClick={handleNext}
          variant="contained"
          loading={isLoading}
          loadingPosition="start"
        >
          {continueBtnLabel}
        </LoadingButton>
      )}
    </div>
  );
};

export default NavigationButtons;
