import React, { useState } from "react";
import "./styles.css";
import { ReactFlowProvider } from "reactflow";
import Flow from "./Flow";
import { Dialog, Slide } from "@mui/material";
import FlowDisplay from "./FlowDisplay";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BasicFlow = ({
  steps,
  fullScreen = false,
  onCollapsed = () => null,
  title = "Preview",
  showExpandIcon = true,
  json,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(fullScreen);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    onCollapsed();
  };

  const ModalWrapper = ({ children }) => {
    return isModalOpen ? (
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={toggleModal}
        TransitionComponent={Transition}
      >
        <FlowDisplay
          toggleModal={toggleModal}
          title={title}
          steps={steps}
          showExpandIcon={showExpandIcon}
          json={json}
        >
          {children}
        </FlowDisplay>
      </Dialog>
    ) : (
      <Flow data={steps} toggleModal={toggleModal} showExpandIcon={true} />
    );
  };

  return (
    <ReactFlowProvider>
      <ModalWrapper />
    </ReactFlowProvider>
  );
};

export default BasicFlow;
