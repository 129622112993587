import { Tooltip, Typography, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EventSeatOutlinedIcon from "@mui/icons-material/EventSeatOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AirlineStopsOutlinedIcon from "@mui/icons-material/AirlineStopsOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import SettingsEthernetOutlinedIcon from "@mui/icons-material/SettingsEthernetOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import MemoryOutlineIcon from "@mui/icons-material/Memory";
import { useCallback } from "react";
import { Handle, Position } from "reactflow";

const getIcon = (node) => {
  switch (node.name) {
    case "HTTP Listener":
      return LanguageOutlinedIcon;
    case "APIKit Router":
      return RouterOutlinedIcon;
    case "Database Connection":
      return SettingsEthernetOutlinedIcon;
    case "Error Handling":
      return ErrorOutlineIcon;
    case "call":
      return LocalPhoneOutlinedIcon;
    case "event":
      return EventSeatOutlinedIcon;
    case "Retrieve": //sql query
    case "Transform Message":
    case "Transform":
      return AirlineStopsOutlinedIcon;
    case "Logger":
      return InfoOutlinedIcon;
    case "Security":
    case "Security Manager":
      return SecurityOutlinedIcon;
    case "Documentation":
      return AssignmentOutlinedIcon;
    case "Testing":
      return BugReportOutlinedIcon;
    case "Batch Processing":
      return MemoryOutlineIcon;

    default:
      return ErrorOutlineIcon;
  }
};

const Node = ({ data: nodeData }) => {
  const Icon = getIcon(nodeData);

  if (!nodeData || !nodeData.id || !nodeData.label) {
    // Return null if necessary data is missing
    return null;
  }

  return (
    <div className="node-container">
      {nodeData.start === true && (
        <Handle
          type="target"
          position="left"
          id={`${nodeData.id}_left`} // Assign a unique ID for the handle
          style={{
            border: "1px solid rgb(152, 152, 152)",
            backgroundColor: "white",
          }}
        />
      )}

      <div
        style={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Tooltip title={nodeData.label} arrow placement="top">
          <div>
            <IconButton>
              <Icon fontSize="large" />
            </IconButton>
          </div>
        </Tooltip>

        <Typography variant="body1" align="center" gutterBottom>
          {nodeData.label}
        </Typography>
      </div>

      {!nodeData.final && (
        <Handle
          type="source"
          position="right"
          id={`${nodeData.id}_right`} // Assign a unique ID for the handle
          style={{
            border: "1px solid rgb(152, 152, 152)",
            backgroundColor: "white",
          }}
        />
      )}
    </div>
  );
};

export default Node;
