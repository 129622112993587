import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchSidebarData = createAsyncThunk(
  "app/fecthSidebarData ",
  async (pageNumber) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/sidebarData`
    );
    return data;
  }
);

const getSidebarDataSlice = createSlice({
  name: "sidebarData",
  initialState: { data: [], status: "idle", error: null, alerts: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSidebarData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSidebarData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchSidebarData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getSidebarDataSlice.reducer;
