import Constants from "./constants/AppConstants";
import NotificationConstants from "./constants/NotificationConstants";
import * as uuid from "uuid";

const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;

export const modifyStepsLabel = (stepIndex, label, steps) => {
  try {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex] = { ...updatedSteps[stepIndex], label: label };
    return updatedSteps;
  } catch (err) {
    console.error("Invalid step index");
  }
};

export const validProjectInput = (projectInput, setProjectInput, notify) => {
  let errorMessage = "";

  if (!projectInput.campaign) {
    errorMessage = "Team is required";
  }
  if (!projectInput.projectName) {
    errorMessage = "Project name is required";
  }
  if (errorMessage) {
    setProjectInput((pre) => ({
      ...pre,
      isError: errorMessage,
    }));
    notify(true, errorMessage, NOTIFICATION_TYPE.error, 5000);
    return false;
  }
  return true;
};

export const validPromptInput = (promptInput, setPromptInput, notify) => {
  const { shipPrompt, shipZip, shipTrad } = Constants.INPUT_TYPE || {};
  let errorMessage = "";
  if (shipPrompt === promptInput.type && !promptInput.text) {
    errorMessage = "Describe your project";
  }

  if ([shipZip, shipTrad].includes(promptInput.type)) {
    if (!promptInput.file) {
      notify(
        true,
        "Please upload your project as zip",
        NOTIFICATION_TYPE.error,
        5000
      );
      return false;
    }
  }
  if (errorMessage) {
    setPromptInput((pre) => ({
      ...pre,
      isError: errorMessage,
    }));
    notify(
      true,
      "Please describe your project in details",
      NOTIFICATION_TYPE.error,
      5000
    );
    return false;
  }

  return true;
};

export const formatApiPayloadToFlow = (data) => {
  const flows = data.flows;
  const endNodeId = uuid.v4();
  const components = [
    // {
    //   id: "0",
    //   data: { label: "Start", name: "Start", start: true, id: "0" },
    //   position: { x: 0, y: 0 },
    //   type: Constants.NODE_TYPE.activity,
    // },
    // {
    //   id: endNodeId,
    //   data: { label: "End", final: true },
    //   type: Constants.NODE_TYPE.activity,
    // },
  ];
  const links = [];
  const ids = new Set();
  // let componentNamesWithIds = { start: "0", end: endNodeId };

  flows.forEach((flow, flowIndex) => {
    const includingGrpComponents = [
      ...flow.components,
      // ...flow.groupActivities
    ];
    includingGrpComponents.forEach((component, componentIndex) => {
      const {
        sequenceId,
        start,
        name,
        parentId,
        activityType,
        type,
        end = false,
      } = component || {};
      let id = sequenceId ? sequenceId.toString() : uuid.v4();

      if (ids.has(id)) {
        // Handle duplicate sequence ids
        id = uuid.v4();
        console.error("Duplicate sequence id found in flow:", id);
      }
      ids.add(id);

      try {
        components.push({
          id,
          start,
          type,
          data: {
            label: name,
            id,
            activityType,
            ...component,
          },
        });
      } catch (error) {
        console.error(
          `Error occurred while adding component data at Flow ${flowIndex}, Component ${componentIndex}:`,
          error
        );
      }

      try {
        if (parentId && parentId.length > 0) {
          const parentIdStr = parentId[0].toString();
          links.push({
            id: `e${parentIdStr}->${id}`,
            source: parentIdStr,
            target: id,
            type: "smoothstep",
          });
          if (end) {
            links.push({
              id: `e${id}->${endNodeId}`,
              source: id,
              target: endNodeId,
              type: "smoothstep",
            });
          }
        }
      } catch (error) {
        console.error(
          `Error occurred while adding link data at Flow ${flowIndex}, Component ${componentIndex}:`,
          error
        );
      }
    });
  });
  // components.push(endNode);

  // flows.forEach((flow) => {
  //   flow.links.forEach((link, linkIndex) => {
  //     const source = componentNamesWithIds[link.from.toLowerCase()];
  //     const target = componentNamesWithIds[link.to.toLowerCase()];
  //     if (!source || !target) {
  //       console.log("not found", link.from, link.to);
  //     }
  //     links.push({
  //       id: `e-${source}=>${target}-${links.length}`,
  //       source: source,
  //       target: target,
  //       type: "smoothstep",
  //     });
  //   });
  // });
  return { nodes: components, edges: links };
};

export const mapCustomization = (customizationStr) => {
  const custJson = JSON.parse(customizationStr);
  return Object.entries(custJson).map(([k, v]) => ({
    key: k,
    value: v,
  }));
};
