import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/organisms/table/Table";
import { useSelector, useDispatch } from "react-redux";
import Filters from "../Filters/filters";
import { applyFilters } from "../Filters/helpers";
import { fetchAllAppNodesData } from "../../../shared/redux/getAppNodesListSlice";
import CustomModel from "../CustomModel/customModel";
import { machineColumns } from "../Machines/constant";
import { appSpaceColumns } from "../Bw6AppDetails/constants";
import ConfigModel from "../CustomModel/configModel";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-center h-12 uppercase text-white sticky top-0 z-10",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "bg-white min-w-8 truncate text-ellipsis text-center text-sm py-4 px-6 align-middle border-b border-secondary-100",
};

const initialFilters = [
  {
    type: "TEXT",
    key: "AppNodeName",
    placeholder: "Name",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "AppSpaceName",
    placeholder: "AppSpace",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "ServerName",
    placeholder: "Machine",
    value: "",
  },
];

const AppNodes = () => {
  const location = useLocation();
  const { pathname } = location;
  const lastSlashIndex = pathname.lastIndexOf("/");
  const domainName =
    lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : null;
  const data = useSelector((state) => state?.appNodes?.data);
  console.log("app-data", data);
  const machinesData = useSelector(
    (state) => state?.machineData?.data
  );
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModelType, setSelectedModelType] = useState({
    columns: null,
    title: null
  });
  const [isConfigModel, setIsConfigModel] = useState(false)

  const [filters, setFilters] = useState(initialFilters);
  const [dropdownValues, setDropdownValues] = useState({});
  const [rowData, setRowData] = useState([]);
  const handleViewConfig= (config) =>{
    setSelectedData(config)
    setIsConfigModel(true)
  }

  const columns = useMemo(
    () => [
      { Header: "AppNode Name", accessor: "AppNodeName"},
      { Header: "Management Port", accessor: "ManagementPort" },
      { Header: "Config State", accessor: "ConfigState" },
      {Header: "Status", accessor: "Status"},
      { Header: "UpTime", accessor: "UpTime" },
      // { Header: "Agent", accessor: "Agent" },
      { Header: "Default Min Heap Size", accessor: "DfltMinHeapSize" },
      { Header: "Default Max Heap Size", accessor: "DfltMaxHeapSize" },
      { Header: "Min Heap Size", accessor: "MinHeapSize" },
      { Header: "Max Heap Size", accessor: "MaxHeapSize" },
      { Header: "AppSpace Name", accessor: "AppSpaceName",
      Cell: ({ row }) => (
      <button
        className="hover:text-primary"
        onClick={() => handleOpenModal(row.original, 'AppSpace')}
      >
        {row.original.AppSpaceName}
      </button>
      ),
    },
      { 
        Header: "Machine", 
        accessor: "ServerName",
        Cell: ({ row }) => (
        <button
          className="hover:text-primary"
          onClick={() => handleOpenModal(row.original.ServerId, 'Machine')}
        >
          {row.original.ServerName}
        </button>
        ),
     },
      { 
        Header: "Actions", 
        accessor: "Configuration",
        Cell: ({ row }) => (
        <button
          className="hover:text-primary"
          onClick={() => handleViewConfig(row.original.Configuration)}
        >
          View Config
        </button>
        ),
     },
    ],
    []
  );

  const handleOpenModal = (rowData, type) => {
    let selectedMachine = type==='Machine'?machinesData.find((obj) => obj.ServerId === rowData):rowData

    setSelectedModelType({
      columns: type==='Machine'?machineColumns:appSpaceColumns,
      title: type==='Machine'?selectedMachine?.ServerName:rowData?.AppSpaceName
    })
    setSelectedData(selectedMachine);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsConfigModel(false)
  };

  useEffect(() => {
    const ServerName = new Set();
    const AppSpaceName = new Set();
    data?.forEach((item) => {
      ServerName.add(item.ServerName);
      AppSpaceName.add(item.AppSpaceName);
    });
    setDropdownValues({ ServerName, AppSpaceName });
  }, [data]);

  useEffect(() => {
    const filteredData = applyFilters(filters, data);
    setRowData(filteredData);
  }, [filters, data]);

  const handleFilterChange = (value, index) => {
    const cloned = JSON.parse(JSON.stringify(filters));
    const obj = cloned[index];
    obj.value = value;
    setFilters(cloned);
  };

  const onFilterReset = () => {
    setFilters(initialFilters);
  };

  const fetchData = useCallback((pageIndex) => {
    console.log("Async Data CALLED", pageIndex);
    dispatch(fetchAllAppNodesData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  return (
    <div className="flex flex-col py-6">
      <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        {domainName && (
          <span className="ml-2 text-primary text-lg font-medium hover:text-primaryLight">
            &gt; {domainName.charAt(0).toUpperCase() + domainName.slice(1)}
          </span>
        )}
      </div>
      <div className="self-start my-6 p-0">
        <Filters
          filters={filters}
          onFilterChange={handleFilterChange}
          onFilterReset={onFilterReset}
          dropdownValues={dropdownValues}
        />
      </div>
      <div className="bg-white rounded-lg p-2 shadow-md">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
        {
          <Table
            columns={columns}
            data={rowData}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        }
      </div>
      </div>
      </div>
      <CustomModel
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedData}
        columns={selectedModelType?.columns}
        title={selectedModelType?.title}
      />
      <ConfigModel 
        open={isConfigModel}
        onClose={handleCloseModal}
        data={selectedData}
        />
    </div>
  );
};

export default AppNodes;
