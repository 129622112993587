import React from "react";

const TableSkelton = ({ rows = 5, columns = 3, tdClassName = "" }) => {
  const renderSkeletonRows = () => {
    return Array(rows)
      .fill("")
      .map((r, i) => (
        <tr key={`skeleton-row-${i}`} className="bg-white">
          {Array(columns)
            .fill("")
            .map((column, columnIndex) => (
              <td
                key={`skeleton-cell-${i}-${columnIndex}`}
                className={`py-4 px-6  ${tdClassName}`}
              >
                <div className="h-4 bg-slate-100 animate-pulse rounded-sm"></div>
              </td>
            ))}
        </tr>
      ));
  };

  return (
    <div className="relative overflow-x-auto rounded-sm">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <tbody>{renderSkeletonRows()}</tbody>
      </table>
    </div>
  );
};

export default TableSkelton;
