import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBW6AppDetailData } from "../../../shared/redux/getBW6AppDetailsDataSlice";
import Table from "../../../shared/components/modecules/Table";
import SummaryCard from "../../organisms/activity/activityDetails/SummaryCard";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import CustomModel from "../CustomModel/customModel";
import { appSpaceColumns } from "./constants";
import ConfigModel from "../CustomModel/configModel";
import { domainColumns } from "../Domains/constant";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "text-xs text-white uppercase text-center bg-secondary dark:bg-gray-700 dark:text-gray-400",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "max-w-60 min-w-8 truncate text-ellipsis text-center text-sm py-2 px-2 align-middle border-b border-secondary-100",
};

const Bw6Appdetails = () => {
  const { appId } = useParams();
  const data = useSelector((state) => state?.BW6appDetailData?.data);
  const [selectedData, setSelectedData] = useState(null);
  const [isConfigModel, setIsConfigModel] = useState(false)
  const [selectedModelType, setSelectedModelType] = useState({
    columns: null,
    title: null
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const domainsData = useSelector((state) => state.domainData?.data);
  const handleOpenModal = (data, type) => {
    data = type==='domain'?domainsData.find((dom) => dom.DomainName === data):data
    setSelectedModelType({
      columns: type==='appSpace'?appSpaceColumns:type==='domain'?domainColumns:appNodesColumns,
      title: type==='appSpace'?data?.AppSpaceName:type==='domain'?data?.DomainName:data?.AppNodeName
    })
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleViewConfig= (config) =>{
    setSelectedData(config)
    setIsConfigModel(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsConfigModel(false)
  };

  const detailsTableColumns = [
    { title: "Application Name", key: "ApplicationName" },
    { title: "Status", key: "Status",
    render: (value, rowData)=>(
      <span
          className={`rounded-md w-16 p-1 text-center text-white ${
            value === "Running"
              ? "bg-green-500"
              : "bg-red-500"
          }`}
        >
          {value ? value : "Stopped"}
        </span>
    ) },
    { title: "Config Status", key: "ConfigStatus" },
  ];

  const detailsTableColumns2 = [
    // { title: "Application Name", key: "ApplicationName" },
    { title: "Details", key: "Details" },
    { title: "Profile", key: "Profile" },
    { title: "AppNode Name", key: "AppNodeName" , 
    render: (value, rowData) => (
      <button
          className="hover:text-primary"
          onClick={() => handleOpenModal(rowData, 'appNode')}
        >
          {value}
        </button>
    )
  },
    { title: "AppSpace Name", key: "AppSpaceName" , 
    render: (value, rowData) => (
      <button
          className="hover:text-primary"
          onClick={() => handleOpenModal(rowData, 'appSpace')}
        >
          {value}
        </button>
    )
  },
    { title: "Domain", key: "DomainName", 
    render: (value, rowData) => (
      <button
          className="hover:text-primary"
          onClick={() => handleOpenModal(value, 'domain')}
        >
          {value}
        </button>
    ) },
  ];
  
  const CardItems = [
    {
      label: "Application Name",
      key: "ApplicationName",
      bg: "bg-white text-secondary",
    },
    {
      label: "Version",
      key: "Version",
      bg: "bg-white text-secondary",
    },
    {
      label: "Description",
      key: "Description",
      bg: "bg-white text-secondary",
    },
    {
      label: "Status",
      key: "AppStatus",
      bg: "bg-white text-secondary",
      formatter: (value, key)=>
        <div
          className={`rounded-md p-1 text-center text-white text-nowrap ${
            value === "Running"
              ? "bg-green-500"
              : value === "Partial Running" ?
                     "bg-blue-500 scroll-container"
                    : "bg-red-500"
          }`}
        >
          {value ? value : "Stopped"}
        </div>
    },
    {
      label: "Owner",
      key: "Owner",
      bg: "bg-white text-secondary",
    },
    { label: "Machine", key: "ServerName",bg: "bg-white text-secondary", },
  ];

  useEffect(() => {
    dispatch(fetchBW6AppDetailData(appId));
    const intervalId = setInterval(()=>{dispatch(fetchBW6AppDetailData(appId))}, 60000); // 300000ms = 5 minutes
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, appId]);

  const appNodesColumns = [
    { label: "Management Port", key: "ManagementPort" },
      { label: "Status", key: "AppNodeStatus",render: (value) => (
        <span
          className={`rounded-md w-16 p-1 text-center text-white ${
            value === "Running"
              ? "bg-green-500"
                    : "bg-red-500"
          }`}
        >
          {value ? value : "Stopped"}
        </span>
      ), },
      { label: "Agent", key: "Agent" },
      { label: "Config State", key: "ConfigState" },
      { label: "Up Time", key: "UpTime" },
      {
        label: "Default Min Heap Size",
        key: "DfltMinHeapSize",
      },
      { label: "Default Max Heap Size", key: "DfltMaxHeapSize" },
      { label: "Min Heap Size", key: "AppNodeMinHeapSize" },
      { label: "Max Heap Size", key: "AppNodeMaxHeapSize" },
      { label: "Machine", key: "ServerName" },
      { 
        label: "Configuration", 
        key: 'Configuration', 
        render:(value)=>
          <span className="hover:text-primary cursor-pointer"
        onClick={() => handleViewConfig(value)}
      >
        View Config</span>
      },
      
  ]

  return (
    <div className="p-2 flex gap-4 flex-col ">
    <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        <Link
          to="/d/bw6applications"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          &gt; BW6 Applications
        </Link>
        {data[0]?.ApplicationName && (
          <span className="ml-2 text-primary text-lg font-medium hover:text-primaryLight cursor-pointer">
            &gt; {data[0]?.ApplicationName.charAt(0).toUpperCase() + data[0]?.ApplicationName.slice(1)}
          </span>
        )}
      </div>
      <SummaryCard
        label="Summary"
        items={CardItems}
        jobDetail={data[0]}
        col={2}
        p="text-sm text-gray-400 p-2 flex border-gray-100  border-b my-1 gap-2 items-center"
      />
      <div className="flex gap-4 w-full">
      <div className="bg-white rounded-lg p-2 shadow-md">
        <div className="h-72 rounded-md">
          <div className="h-72 max-h overflow-y-auto">
            <Table
              columns={[...detailsTableColumns,...detailsTableColumns2]}
              data={data}
              isStatic={true}
              customClasses={customClasses}
              tdClassName={customClasses.td}
              thClassName={customClasses.th}
              theadClassName = {customClasses.thead}
            />
          {/* </div>
        </div>
      </div>
      <div className="bg-white rounded-lg p-2 shadow-md flex-grow">
        <div className="h-72 rounded-md">
          <div className="h-72 max-h overflow-y-auto">
            <Table
              columns={detailsTableColumns2}
              data={data}
              isStatic={true}
              customClasses={customClasses}
              tdClassName={customClasses.td}
              thClassName={customClasses.th}
              theadClassName = {customClasses.thead}
            /> */}
          </div>
        </div>
      </div>
      </div>
      <CustomModel
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedData}
        columns={selectedModelType?.columns}
        title={selectedModelType?.title}
      />
      <ConfigModel 
        open={isConfigModel}
        onClose={handleCloseModal}
        data={selectedData}
        />
    </div>
  );
};

export default Bw6Appdetails;
