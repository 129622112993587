import Table from "../../../shared/components/modecules/Table";
import Graph from "../../modecules/graph";

import AppUtils from "../../../utils/AppUtils";
import { AppConstants } from "../../../utils/constants";
import { IconButton, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

const { Completed, Failed, Created, InProgress } = AppConstants.PROJECT_STATUS;
const ActivityTable = ({ data, onRowViewClicked, hideActions = false }) => {
  const columns = [
    {
      key: "job_name",
      title: "Name",
      render: (value, rowData) => (
        <Link
          component="button"
          variant="button"
          scope="row"
          underline={"hover"}
          className="text-left truncate text-ellipsis"
          onClick={() => onRowViewClicked(rowData.id)}
        >
          {value ?? "JOB NAME"}
        </Link>
      ),
    },
    {
      key: "job_type",
      title: "Type",
      render: (value) => (
        <div className="uppercase text-center ">{value}</div>
      ),
    },
    {
      key: "start_time",
      title: "Start Date",
      render: (value) => (
        <div className=" text-center">
          {AppUtils.getFormattedDate(value)}
        </div>
      ),
    },
    {
      key: "job_status",
      title: "Status",
      render: (value, rowData) => (
        <div className="flex justify-center items-center">
          {[Completed, Failed].includes(value) && (
            <Graph status={value} rowData={rowData} width="{70}" height="{70}" />
          )}

          {[InProgress, Created].includes(value) && (
            <div className="loader flex items-center gap-2">
              <div className="w-3 h-3 rounded-full moving-dot" />
              <p>{value}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "end_time",
      title: "Time Elapsed",
      render: (value, { start_time }) => {
        if (value) {
          return (
            <div className="flex justify-center text-ellipsis">
              <div className="w-32 truncate text-center">
                {AppUtils.getTimeDiff(start_time, value)}
              </div>
            </div>
          );
        }
        return <div className="px-10 text-center">-</div>;
      },
    },
    ...(hideActions
      ? []
      : [
          {
            key: "id",
            title: "Actions",
            render: (id) => (
              <div className="flex gap-2 justify-center text-center border-l-2 min-w-32">
                <IconButton onClick={() => onRowViewClicked(id)}>
                  <VisibilityIcon className="text-primary" />
                </IconButton>
                <IconButton className="text-primary">
                  <EditIcon />
                </IconButton>
                <IconButton className="text-error">
                  <DeleteIcon />
                </IconButton>
              </div>
            ),
          },
        ]),
  ];

  return (
    <div className="">
      <Table data={data} columns={columns}
       tableclassName="w-full text-left border-separate border-spacing-y-[10px] sm:mt-2"
       tdClassName="px-5 py-0 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 s first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
       thClassName="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 bg-primary text-white dark:bg-darkmode-600 first:rounded-l-[0.6rem] last:rounded-r-[0.6rem]"
       theadClassName="" />
    </div>
  );
};

export default ActivityTable;
