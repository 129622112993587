import React from "react";

const ToggleButtonGroup = ({ value, onChange, items }) => {
  return (
    <div className="flex space-x-1 bg-secondary-100 p-1 rounded-md">
      {items.map(({ value: view, icon: Icon }) => (
        <button
          key={view}
          className={`px-2 py-1 border rounded-md focus:outline-none ${
            view === value ? "bg-secondary-500 text-white" : "text-gray-700"
          }`}
          onClick={() => onChange(view)}
        >
          <Icon className="w-5 h-5" />
        </button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
