import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/organisms/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllDomainData } from "../../../shared/redux/getDomainDataSlice";
import { useNavigate } from "react-router-dom";
import DomainModal from "./DomainModal";
import Filters from "../Filters/filters";
import { applyFilters } from "../Filters/helpers";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-center h-12 uppercase text-white sticky top-0 z-10",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "bg-white min-w-8 truncate text-ellipsis text-center text-sm py-4 px-6 align-middle border-b border-secondary-100",
};

const initialFilters = [
  {
    type: "TEXT",
    key: "DomainName",
    placeholder: "Name",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "Machine",
    placeholder: "Machine",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "ProductType",
    placeholder: "Product",
    value: "",
  },
];

const Domains = () => {
  const location = useLocation();
  const { pathname, state } = location;

  const [filters, setFilters] = useState(initialFilters);
  const [dropdownValues, setDropdownValues] = useState({});
  const [rowData, setRowData] = useState([]);

  const data = useSelector((state) => state.domainData?.data);
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const ProductType = new Set();
    data?.forEach((item) => {
      ProductType.add(item.ProductType);
    });
    setDropdownValues({ ProductType });
  }, [data]);

  useEffect(() => {
    const filteredData = applyFilters(filters, data);
    setRowData(filteredData);
  }, [filters, data]);

  useEffect(() => {
    if (state?.ProductType) {
      handleFilterChange(state.ProductType, 2);
    }
  }, [state]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDomainClick = (row) => {
    setSelectedDomain(row.original);
    setIsModalOpen(true);
  };

  const handleApplicationClick = (row) => {
    if(row.original.ProductType==='BW5'){
      navigate(`/d/bw5applications`);
    }else{
      navigate(`/d/bw6applications`);
    }
  };

  const handleMachineClick = (row) => {
    navigate(`/d/machines`);
  };

  // Extract domain name from URL path
  const lastSlashIndex = pathname.lastIndexOf("/");
  const domainName =
    lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : null;

  const fetchData = useCallback((pageIndex) => {
    console.log("Async Data CALLED", pageIndex);
    dispatch(fetchAllDomainData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  const handleFilterChange = (value, index) => {
    const cloned = JSON.parse(JSON.stringify(filters));
    const obj = cloned[index];
    obj.value = value;
    setFilters(cloned);
  };

  const onFilterReset = () => {
    setFilters(initialFilters);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Domain Name",
        accessor: "DomainName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => handleDomainClick(row)}
          >
            {row.original.DomainName}
          </button>
        ),
      },
      // { Header: "Port #", accessor: "Port" },
      {
        Header: "Machine #",
        accessor: "machineCount",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => handleMachineClick(row)}
          >
            {row.original.serverCount}
          </button>
        ),
      },
      {
        Header: "Application #",
        accessor: "applicationCount",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => handleApplicationClick(row)}
          >
            {row.original.applicationCount}
          </button>
        ),
      },
      { Header: "Product Type", accessor: "ProductType" },
    ],
    []
  );

  return (
    <div className="flex flex-col py-6">
      <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        {domainName && (
          <span className="ml-1 text-primary text-lg font-medium hover:text-primaryLight">
            {" "}
            <span className="text-secondaryLight">&gt; </span>{" "}
            {domainName.charAt(0).toUpperCase() + domainName.slice(1)}
          </span>
        )}
      </div>
      <div className="self-start my-6 p-0">
      <Filters
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterReset={onFilterReset}
        dropdownValues={dropdownValues}
      />
      </div>
      <div className="bg-white rounded-lg p-2 shadow-md">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
        {
          <Table
            columns={columns}
            data={rowData}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        }
      </div>
      </div>
      </div>
      <DomainModal
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedDomain}
      />
    </div>
  );
};

export default Domains;
