import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Stepper, Typography } from "@mui/material";

export default function StepperComponent({ activeStep = 1, steps = [] }) {
  return (
    <Box
      // sx={{ border: "1px groove", padding: 1, borderRadius: 1 }}
      className="bg-primary font-poppins shadow-md p-4 rounded-md"
    >
      <Stepper
        activeStep={activeStep}
        connector={<></>}
        style={{ justifyContent: "space-between" }}
      >
        {steps.map(({ label, step }, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={() => null}>
              <Typography
                variant="subtitle2"
                className={
                  activeStep === index
                    ? "text-white font-medium text-lg font-poppins"
                    : "text-gray-400 font-medium text-lg font-poppins"
                }
              >
                {`Step ${index + 1} - ${step}`}
              </Typography>

              {activeStep === index && (
                <Typography className="text-white " fontSize={14}>
                  Current
                </Typography>
              )}
              {activeStep > index && (
                <Typography variant="body2">{label}</Typography>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
