import axios from "axios";

class TokenService {
  constructor() {
    this.BASE_URL =
      "https://ship-ai-queue.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io";
  }

  static getToken() {
    const token = document.cookie
      .split(";")
      .find((cookie) => cookie.trim().startsWith("token="));
    return token ? token.split("=")[1] : null;
  }

  static setToken(token) {
    const expirationDate = new Date(Date.now() + 30 * 60 * 1000); // 30 minutes in milliseconds
    const formattedExpirationDate = expirationDate.toUTCString();
    document.cookie = `token=${token}; expires=${formattedExpirationDate}; path=/;`;
  }

  static deleteToken() {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  async refreshToken() {
    const userId = "schitipotu@sageitinc.com";
    const password = "admin123";
    const payload = new FormData();
    payload.append("user_id", userId);
    payload.append("password", password);
    try {
      const response = await axios.post(
        `${this.BASE_URL}/generate_token`,
        payload
      );
      const newToken = response.data.access_token;
      TokenService.setToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  }
}

export default TokenService;
