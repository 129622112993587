import React from "react";

const CircularSpinner = ({
  height = "h-20",
  width = "w-full",
  spinnerHeight = 10,
  borderColor = "border-primaryDark",
}) => {
  return (
    <div className={`flex items-center justify-center ${height} ${width}`}>
      <div className="text-center">
        <div
          className={`h-${spinnerHeight} w-${spinnerHeight} ${borderColor} animate-spin rounded-full border-t-2 `}
        />
      </div>
    </div>
  );
};

export default CircularSpinner;
