import axios from "axios";
import TokenService from "./TokenService";

class AuthService {
  baseUrl =
    "https://ship-ai-queue.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io";

  async login(userId, password) {
    try {
      const payload = new FormData();
      payload.append("user_id", userId);
      payload.append("password", password);
      const response = await axios.post(
        `${this.baseUrl}/generate_token`,
        payload
      );
      const newToken = response.data.access_token;
      TokenService.setToken(newToken);

      return newToken;
    } catch (error) {
      console.error("Something went wrong while log in", error);
      // throw error;
      return null;
    }
  }

  logout() {
    TokenService.deleteToken();
    return true;
  }

  isAuthenticated() {
    const token = TokenService.getToken();
    return !!token;
  }
}

export default AuthService;
