import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularSpinner from "../atoms/spinner";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const MAX_TIME_IN_MS = 5000;

const Processing = ({ projectName = "SHIP", handleClose }) => {
  const [timer, setTimer] = useState(MAX_TIME_IN_MS / 1000);
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      handleClose();
    }, MAX_TIME_IN_MS);

    return () => {
      clearInterval(countdown);
      clearTimeout(redirectTimeout);
    };
  }, [navigate]);

  return (
    <div style={{ minHeight: "calc(100% - 64px)" }}>
      <div className="flex flex-col justify-center items-center pt-20">
        <CheckCircleOutlineIcon
          className="text-green-400 mb-4"
          style={{ fontSize: "5rem" }}
        />
        <h1 className="text-3xl font-bold mb-8 text-green-500">Success!</h1>
        <p className="text-lg text-gray-600 mb-8">
          Your project creation was successful. Please wait while we process
          your request.
        </p>

        <CircularSpinner />

        <p className="text-sm text-gray-600">
          Redirecting in {timer} seconds...
        </p>

        <button
          className="bg-secondary-500 hover:bg-secondary-600 border py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-8"
          onClick={handleClose}
        >
          Go to project details
        </button>
      </div>
    </div>
  );
};

export default Processing;
