import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchAllAlertData = createAsyncThunk(
  "app/fetchAllAlertData ",
  async (pageNumber) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/alerts`
    );
    return data;
  }
);

const getAlertsDataSlice = createSlice({
  name: "alertsData",
  initialState: { data: [], status: "idle", error: null, alerts: [], nodeDown: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAlertData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAlertData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.alerts = action.payload.map((app) => app.AlertDescription);
        state.nodeDown = action.payload.map((alert) => `${alert?.AlertName}~${alert?.NodeName}`);
      })
      .addCase(fetchAllAlertData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getAlertsDataSlice.reducer;
