import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchAllApplicationData = createAsyncThunk(
  "app/fetchAllApplicationData",
  async (pageNumber) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/applications_list`
    );

    return data;
  }
);

const getApplicationDataSlice = createSlice({
  name: "applicationsData",
  initialState: { data: [], status: "idle", error: null, applications: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllApplicationData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllApplicationData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.applications = [...new Set(action.payload.map(obj => obj.ApplicationName))]
      })
      .addCase(fetchAllApplicationData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getApplicationDataSlice.reducer;
