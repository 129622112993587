export const applyFilters = (filters, data) => {
  console.log(filters);
  return data.filter((item) => {
    return filters.every(({ key, value, type }) => {
      if (!value) return true;

      const itemValue = item?.[key]?.toString()?.toLowerCase();
      const searchQuery = value?.toLowerCase();

      if (type === "DROPDOWN") {
        return itemValue === searchQuery;
      }
      if (type === "TEXT") {
        return itemValue?.includes(searchQuery);
      }
    });
  });
};
