export const appSpaceColumns = [
    { label: "Min Nodes", key: "MinNodes" },
    { label: "Status", key: "AppSpaceStatus",render: (value) => (
      <span
        className={`rounded-md w-16 p-1 text-center text-white ${
          value === "Running"
            ? "bg-green-500"
                  : "bg-red-500"
        }`}
      >
        {value ? value : "Stopped"}
      </span>
    ), },
    { label: "Nodes Count", key: "NodesCount" },
    { label: "Applications Count", key: "ApplicationsCount" },
    { label: "Description", key: "AppSpaceDescription" },
    {
      label: "Min Heap Size",
      key: "AppSpaceMinHeapSize",
    },
    { label: "Max Heap Size", key: "AppSpaceMaxHeapSize" },
    // { label: "Machine", key: "ServerName" },
  ];
  
export const appNodesColumns = [
  { label: "Management Port", key: "ManagementPort" },
    { label: "Status", key: "AppNodeStatus", render: (value) => (
      <span
        className={`rounded-md w-16 p-1 text-center text-white ${
          value === "Running"
            ? "bg-green-500"
                  : "bg-red-500"
        }`}
      >
        {value ? value : "Stopped"}
      </span>
    ), },
    { label: "Agent", key: "Agent" },
    { label: "Config State", key: "ConfigState" },
    { label: "Up Time", key: "UpTime" },
    {
      label: "Default Min Heap Size",
      key: "DfltMinHeapSize",
    },
    { label: "Default Max Heap Size", key: "DfltMaxHeapSize" },
    { label: "Min Heap Size", key: "AppNodeMinHeapSize" },
    { label: "Max Heap Size", key: "AppNodeMaxHeapSize" },
    { label: "Machine", key: "ServerName" },
]