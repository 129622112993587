const ListItem = ({ label, value, render }) => {
  return (
    <>
      <div className="flex justify-between">
        <p className="text-secondary">{label}</p>
        <span className="font-normal text-gray-500"> {render?render(value, label):value}</span>
      </div>
    </>
  );
};

export default ListItem;
