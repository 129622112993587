import dagre from "dagre";
import { MarkerType } from "reactflow";

export const generateFlow = (width, height, data) => {
  const flow = new dagre.graphlib.Graph();
  flow.setGraph({
    rankdir: "LR",
  });
  flow.setDefaultEdgeLabel(() => ({}));
  // Set nodes
  data?.forEach((node) => {
    flow?.setNode(node?.id, {
      width,
      height,
      ...node,
    });
  });
  // Set edges
  data?.forEach(({ id, previous }) => {
    previous?.forEach(({ stepId: previousId }) => {
      flow.setEdge(previousId, id);
    });
  });

  dagre.layout(flow);
  return flow.nodes().map((i) => flow.node(i));
};

export const getElements = (flow, mode, steps) => {
  return flow
    ?.map((node) => ({
      id: `${mode}-${node?.id}`,
      type: "basic",
      data: { ...node, label: node.name },
      position: { x: node.x, y: node.y },
      sourcePosition: "right",
      targetPosition: "left",
      className: mode === "profile" ? "node" : "fullnode",
    }))
    ?.concat(
      steps
        ?.map(({ id, previous }) =>
          previous?.map(({ stepId: previousId, count, label }) => ({
            id: `${mode}-${id}-${previousId}}`,
            source: `${mode}-${previousId}`,
            target: `${mode}-${id}`,
            arrowHeadType: "arrow",
            style: {
              strokeWidth: 2,
              stroke: "rgb(152,152,152)",
            },
            type: "smoothstep",
            // label: `${label ? label + ": " : ""}addi info`,
            // labelShowBg: true,
            // labelBgPadding: [10, 5],
            // labelBgBorderRadius: 4,
            // labelStyle: {
            //   fontFamily: "Roboto, sans-serif",
            //   fontSize: 15,
            // },
            // labelBgStyle: {
            //   fill: "rgb(217,217,217)",
            //   stroke: "rgb(152,152,152)",
            // },
          }))
        )
        .flat()
    );
};

export const convertResponseIntoFlowJSON = (response) => {
  try {
    if (!response || !response.components || !response.links) {
      throw new Error("Invalid input: components and links are required");
    }

    const { components, links } = response;

    const data = components.map((component) => {
      const previous = links
        ?.filter((link) => link.target === component.name)
        ?.map((link) => ({
          id: link.target + link.source,
          stepId: link.source,
        }));

      return {
        ...component,
        id: component?.name,
        previous,
      };
    });

    return data;
  } catch (error) {
    console.error("Error converting response into flow JSON:", error.message);
    return [];
  }
};

export const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 250;
  const nodeHeight = 120;
  // const isHorizontal = direction !== "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    // node.targetPosition = isHorizontal ? "left" : "top";
    // node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    node.type = "basicNode";
    node.className = "node";
    return node;
  });
  edges.forEach((edge) => {
    edge.markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
    };
    edge.style = {
      strokeWidth: 1.2,
    };
    return edge;
  });
  // console.log("edges", edges);
  return { nodes, edges };
};

export const removeReactFlowWatermark = () => {
  // Get all anchor tags with href="https://reactflow.dev"
  const links = document.querySelectorAll('a[href="https://reactflow.dev"]');

  // Iterate over the anchor tags and add a class to their parent div
  links.forEach((link) => {
    link.parentElement.style.display = "none";
  });
};
