import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchAllBW5ApplicationData = createAsyncThunk(
  "app/fetchAllBW5ApplicationData",
  async (pageNumber) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/bw5applications_list`
    );
    return data;
  }
);

const getBW5ApplicationListSlice = createSlice({
  name: "bw5ApplistData",
  initialState: { data: [], status: "idle", error: null, applications: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBW5ApplicationData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBW5ApplicationData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.applications = [...new Set(action.payload.map(obj => obj.ApplicationName))]
      })
      .addCase(fetchAllBW5ApplicationData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getBW5ApplicationListSlice.reducer;
