import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchBW5AppDetailData = createAsyncThunk(
  "app/fetchBW5AppDetailData",
  async (appId) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/bw5applicationdetails/${appId}`
    );
    return data;
  }
);

const getBW5AppDetailDataSlice = createSlice({
  name: "BW5appDetailData",
  initialState: { data: [], status: "idle", error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBW5AppDetailData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBW5AppDetailData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchBW5AppDetailData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getBW5AppDetailDataSlice.reducer;
