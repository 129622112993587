import React, { useEffect, useState } from "react";
import { useGet } from "../../../shared/hooks";
import { ProjectService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CreateCampaign from "./CreateCampaign";
import CampaignList from "./CampaignList";

const Campaign = () => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const { fetchData, data, loading } = useGet();

  const getProjects = async () => {
    await fetchData(new ProjectService().getProjectsUrl());
  };

  useEffect(() => {
    getProjects();
  }, []);

  const toggleModal = () => {
    setModalOpen((pre) => !pre);
  };

  const onCreateCampaignClicked = () => {
    toggleModal();
    setEditingRowId(null);
  };

  const onRowViewClicked = (campaignId, rowData) => {
    navigate(`/projects?campaignId=` + campaignId, { state: rowData });
  };

  const onCampaignCreated = () => {
    getProjects();
  };

  const handleEdit = (campaignId, rowData) => {
    setModalOpen(true);
    setEditingRowId(campaignId);
  };

  return (
    <div className="mx-auto p-2 mt-1 px-6">
      <div className="flex justify-between items-center mb-4">
        <div className="text-4xl font-medium text-primary">Teams</div>

        <Button variant="contained" onClick={onCreateCampaignClicked}>
          Create team
        </Button>
      </div>
      <div>
        <CampaignList
          loading={loading}
          data={data?.records}
          onRowViewClicked={onRowViewClicked}
          onEditClicked={handleEdit}
        />
        {modalOpen && (
          <CreateCampaign
            handleClose={toggleModal}
            onCampaignCreated={onCampaignCreated}
            id={editingRowId}
          />
        )}
      </div>
    </div>
  );
};

export default Campaign;
