import * as React from "react";
import {Snackbar, Alert} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { hideNotificaiton } from "./notificationSlice";
import { useSelector, useDispatch } from "react-redux";

const Notification = () => {
  const { message, severity, duration, open } = useSelector(state => state.notification);
  const dispatch = useDispatch();


  const handleClose = (event, reason) => {
    if(reason === 'clickaway') {
      return;
    }
    dispatch(hideNotificaiton());
  }

  const actions = (
    <React.Fragment>
      {/* <Button size="small">UNDO</Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open = {open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        autoHideDuration={duration || 5000}
        onClose={handleClose}
        transitionDuration={{ enter: 500, exit: 1000 }}
        TransitionProps={{ enter: true, exit: true }}
        action={actions}
        style={{ width: "-webkit-fill-available" }}
      >
        <Alert action={actions} sx={{ width: "100%" }} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Notification;
