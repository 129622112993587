import React, { useEffect } from "react";
import { useState } from "react";
import RadioButtonWithSubtitle from "../../atoms/radio";
import { AppConstants } from "../../../utils/constants";
import ProjectHeader from "../../modecules/ProjectHeader";
import DropdownWithAutoSuggestion from "../../atoms/DropdownWithAutoSuggestion";
import ProjectService from "../../../services/ProjectService";
import Overlay from "../../atoms/overlay";
import { useGet } from "../../../shared/hooks";
import CreateCampaign from "../campaign/CreateCampaign";
import CustomizationPanel from "../campaign/CustomizationPanel";
import { mapCustomization } from "../../../utils";
import TibcoIcon from "../../../assests/tibco_logo.png";
import MulesoftIcon from "../../../assests/mulesoft_logo.png";
import AzureLogo from "../../../assests/azure_logo.png";
import NodejsLogo from "../../../assests/nodejs_logo.png";
import BoomiLogo from "../../../assests/boomi_logo.png";

const PROJECT_PLATFORM = AppConstants.PROJECT_PLATFORM;
const projectService = new ProjectService();

function ProjectCreationWorkspace({ setProjectInput, projectInput }) {
  const { fetchData, data: campaigns } = useGet();
  const { fetchData: fetchCampaignById } = useGet();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAllProjects = async () => {
    setLoading(true);
    await fetchData(projectService.getProjectsUrl(1, 500));
    setLoading(false);
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const getCampaignById = async (id) => {
    const resp = await fetchCampaignById(projectService.projectByIdUrl(id));
    let custArr = [];
    try {
      custArr = mapCustomization(resp.customization);
    } catch (e) {
      // customization could be null or error while parsing
    }
    return { ...resp, customizations: { function_mapping: custArr } };
  };

  const handleCampaignChange = async (option) => {
    const { name } = option;
    if (option === "ADD_NEW") {
      setProjectInput((prevInput) => ({
        ...prevInput,
        campaign: "",
        isError: "",
      }));
      toggleModal();
      return;
    }
    const { id } = option;
    const resp = await getCampaignById(id);
    setProjectInput((prevInput) => ({
      ...prevInput,
      campaign: JSON.parse(JSON.stringify(resp)), // deep copy
      customizations: resp.customizations,
      isError: "",
    }));
  };

  const handleProjectNameChange = (e) => {
    setProjectInput((prevInput) => ({
      ...prevInput,
      projectName: e.target.value,
    }));
  };

  const handlePlatformChange = (event) => {
    const value = event.target.value;
    setProjectInput((prevInput) => ({
      ...prevInput,
      platform: value,
    }));
  };

  const onCampaignCreated = async (campaign) => {
    handleCampaignChange(campaign);
    await getAllProjects();
  };

  const toggleModal = () => {
    setModalOpen((pre) => !pre);
  };

  const ProjectSource = [
    {
      title: "Boomi",
      subtitle:
        "Integration platform for connecting applications, data, and devices",
      imageSrc: BoomiLogo,
      disabled: false,
      value: PROJECT_PLATFORM.boomi,
    },
    // {
    //   title: "Mulesoft",
    //   subtitle:
    //     "Integration platform for connecting applications, data, and devices",
    //   imageSrc: MulesoftIcon,
    //   disabled: true,
    //   value: PROJECT_PLATFORM.muleSoft,
    // },
    // {
    //   title: "Tibco",
    //   subtitle:
    //     "Enterprise software for companies to use on-premises or as part of cloud computing environments",
    //   imageSrc: TibcoIcon,
    //   disabled: true,
    //   value: PROJECT_PLATFORM.tibco,
    // },
    // {
    //   title: "Azure",
    //   subtitle:
    //     "Integration platform for connecting applications, data, and devices",
    //   imageSrc: AzureLogo,
    //   disabled: true,
    //   value: PROJECT_PLATFORM.azure,
    // },
    // {
    //   title: "Node js",
    //   subtitle:
    //     "Integration platform for connecting applications, data, and devices",
    //   imageSrc: NodejsLogo,
    //   disabled: true,
    //   value: PROJECT_PLATFORM.nodejs,
    // },
  ];

  return (
    <div className="bg-white shadow-md p-6 rounded-md">
      <ProjectHeader
        title="New project"
        subtitle="Create a new project in the following workspace"
      />
      <div className="flex items-center gap-2">
        <input
          type="text"
          placeholder="Enter project name"
          className={`w-1/2 cursor-default h-11 bg-white py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border-0 border-b-2 border-black   sm:text-sm sm:leading-6`}
          value={projectInput.projectName}
          onChange={handleProjectNameChange}
        />
        <div className=" w-1/2">
          <DropdownWithAutoSuggestion
            options={campaigns?.records || []}
            placeholder="Select a team"
            labelKey="name"
            // label="Select a campaign"
            onSelect={handleCampaignChange}
            selectedValue={projectInput?.campaign}
          />
        </div>
      </div>
      {projectInput.isError && (
        <p className="text-red-500 text-sm mt-1">{projectInput.isError}</p>
      )}
      {projectInput.campaign && (
        <div className="mt-2">
          <CustomizationPanel
            customizations={projectInput?.customizations}
            setFormData={setProjectInput}
            customTitle="Customizations"
          />
        </div>
      )}
      <div className="my-4">
        <p className="text-lg font-semibold leading-4 mb-1 text-gray-700">
          Target Platform
        </p>
        <p className="text-sm text-gray-600 mb-2">
          To proceed further, please choose the platform you would like to use
        </p>
        {ProjectSource.map(({ imageSrc, title, subtitle, value, disabled }) => (
          <RadioButtonWithSubtitle
            imageSrc={imageSrc}
            title={title}
            subtitle={subtitle}
            isChecked={projectInput.platform === value}
            disabled={disabled}
            onChange={() =>
              handlePlatformChange({
                target: { value },
              })
            }
          />
        ))}

        {modalOpen && (
          <CreateCampaign
            onCampaignCreated={onCampaignCreated}
            handleClose={toggleModal}
          />
        )}
        {loading && <Overlay />}
      </div>
    </div>
  );
}

export default ProjectCreationWorkspace;
