import axiosInstance from "./AxiosService";

class ProjectService {
  constructor() {
    this.BASE_URL =
      "https://ship-ai-queue.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io";
  }

  async createProject(projectName, userId = "schitipotu@sageitinc.com") {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("name", projectName);
    const response = await axiosInstance.put(
      `${this.BASE_URL}/project`,
      formData
    );
    return response.data;
  }

  async getAllProjects() {
    const response = await axiosInstance.get(`${this.BASE_URL}/projects`);
    return response.data;
  }

  getProjectsUrl = (page = 1, limit = 5) =>
    `${this.BASE_URL}/projects?page=${page}&limit=${limit}`;

  createProjectUrl = () => `${this.BASE_URL}/project`;
  projectByIdUrl = (id) => `${this.BASE_URL}/project/${id}`;

  getProjectById(id) {
    throw new Error("Not implemented");
  }

  updateProject(id, updatedProject) {
    throw new Error("Not implemented");
  }

  deleteProject(id) {
    throw new Error("Not implemented");
  }
}

export default ProjectService;
