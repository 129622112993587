import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationConstants } from '../../../utils/constants';
import CircularSpinner from '../../atoms/spinner';
import Overlay from '../../atoms/overlay';
import { JobService } from '../../../services';
import { AppConstants } from '../../../utils/constants';
import { useGet, useNotify } from '../../../shared/hooks';
import ActivityTable from './ActivityTable';
import ProjectSubmissionWizard from '../createNewProject/ProjectSubmissionWizard';
import { Button, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SummaryCard from './activityDetails/SummaryCard';
import AppUtils from '../../../utils/AppUtils';
const { Completed, Failed, Created, InProgress } = AppConstants.PROJECT_STATUS;
const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;
const filters = ['All', Created, InProgress, Completed, Failed];
const overviewItems = [
  {
    label: 'Projects',
    key: 'total_projects',
  },

  {
    label: 'Flows',
    key: 'total_flows',
  },
  {
    label: 'Activities',
    key: 'total_activities',
  },
  {
    label: 'Average Time',
    key: 'average_time_elapsed',
    formatter: (value) => AppUtils.convertMinsToHoursAndMinutes(value),
  },
];
const Jobs = () => {
  const jobService = new JobService();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [queryParameters] = useSearchParams();
  const campaignId = queryParameters.get('campaignId');
  const { fetchData, data } = useGet();

  const [notify] = useNotify();
  const [jobs, setJobs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState(filters[0]);
  const [createProjectVisible, setCreateProjectVisible] = useState(false);

  const onCreateProjectClick = () => setCreateProjectVisible(true);

  const handleFilterClick = (filter) => {
    if (activeFilter === filter) return;
    setActiveFilter(filter);
    getJobs(filter, campaignId);
    getjobsOverview(filter);
  };

  const getJobs = async (activeFilter, campaignId) => {
    if (loading) return;

    setLoading(true);
    try {
      const resp = await jobService.getAllJobs(activeFilter, campaignId);
      setJobs(resp);
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    } finally {
      setLoading(false);
    }
  };

  const getjobsOverview = async (filter) => {
    await fetchData(jobService.getjobsOverviewUrl(filter));
  };

  useEffect(() => {
    getJobs(undefined, campaignId);
    getjobsOverview(filters[0]);
  }, [campaignId]);

  const handleFetchMore = async () => {
    if (loadingMore) return;

    const {
      metadata: { page },
      records: preRecords,
    } = jobs;
    setLoadingMore(true);
    try {
      const resp = await jobService.getAllJobs(
        activeFilter,
        campaignId,
        page + 1
      );
      const records = [...preRecords, ...resp.records];

      setJobs({ ...resp, records });
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    } finally {
      setLoadingMore(false);
    }
  };

  const NavigateToProjectDetails = (jobId) => {
    navigate(`/projects/${jobId}`);
  };

  const onRowViewClicked = (id) => {
    NavigateToProjectDetails(id);
  };

  const handleRemoveFilter = () => {
    navigate(`/projects`);
  };

  return (
    <>
      <div className="bg-gradient-to-tr p-2 rounded-md font-poppins mt-4 px-6">
        {campaignId && (
          <>
            <div className="bg-warning p-2 text-white text-center mb-2 flex justify-between items-center">
              You are viewing projects for a specific campaign:{' '}
              {state?.name || campaignId}
              <IconButton className="underline" onClick={handleRemoveFilter}>
                <ClearIcon className="inline-block text-white" />
              </IconButton>
            </div>
          </>
        )}
        <div className="flex justify-between items-center mb-4">
          <div className="text-4xl font-medium text-primary">Projects</div>
          <Button
            variant="contained"
            onClick={onCreateProjectClick}
            className="btnSparkle h-[40px]"
          >
            Create Project
          </Button>
        </div>{' '}
        <div className="flex flex-wrap gap-2 justify-between items-center py-4 px-2 rounded-md bg-gray-100">
          <div className="flex gap-2">
            {filters.map((filter, index) => (
              <div
                key={index}
                className={`rounded-md px-3 py-1 cursor-pointer hover:bg-secondary-200  ${
                  activeFilter === filter
                    ? 'bg-primary text-white shadow-lg'
                    : 'bg-white shadow-md'
                }`}
                onClick={() => handleFilterClick(filter)}
              >
                {filter}
              </div>
            ))}
          </div>

          <input
            type="search"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="h-10 px-3 py-2 w-64 rounded-md outline-secondary-300"
          />
        </div>
        <SummaryCard
          items={overviewItems}
          jobDetail={data}
          col={4}
          bgColor=""
          labelColor="primary"
          valueColor="secondary"
          border="border-t-2 border-primary"
          divide="divide-y divide-slate-200"
        />
        <div className="mt-4">
          {loading && <Overlay />}
          <InfiniteScroll
            dataLength={jobs?.records?.length || 0}
            next={handleFetchMore}
            hasMore={jobs?.metadata.page < jobs?.metadata?.page_count}
          >
            <ActivityTable
              data={jobs?.records || []}
              onRowViewClicked={onRowViewClicked}
            />
          </InfiniteScroll>

          {loadingMore && <CircularSpinner />}
          {!loadingMore && jobs?.records?.length == 0 && (
            <div className="mt-4 text-center">No projects founds.</div>
          )}
        </div>
        {createProjectVisible && (
          <ProjectSubmissionWizard
            handleClose={() => setCreateProjectVisible(false)}
          />
        )}
      </div>
    </>
  );
};

export default Jobs;
