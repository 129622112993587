import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchBW6AppDetailData = createAsyncThunk(
  "app/fetchBW6AppDetailData",
  async (appId) => {
    let { data } = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/bw6applicationdetails/${appId}`
    );
    return data;
  }
);

const getBW6AppDetailDataSlice = createSlice({
  name: "BW6appDetailData",
  initialState: { data: [], status: "idle", error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBW6AppDetailData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBW6AppDetailData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action?.payload;
      })
      .addCase(fetchBW6AppDetailData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      });
  },
});

export default getBW6AppDetailDataSlice.reducer;
