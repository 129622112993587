export const machineColumns = [
  { label: "IP Address", key: "IpAddress" },
  { label: "OS", key: "OperatingSystem" },
  { label: "CPU Count", key: "Cpus" },
  { label: "Cores / CPU", key: "Cores" },
  { label: "File System(s)", key: "fileSystem" },
  { label: "Total Memory", key: "MemoryTotal" },
  { label: "Used Memory", key: "MemoryUsed" },
  { label: "Free Memory", key: "MemoryFree" },
  { label: "Total DiskSpace", key: "DiskSpaceTotal" },
  { label: "Used DiskSpace", key: "DiskSpaceUsed" },
  { label: "Free DiskSpace", key: "DiskSpaceFree" },
  { label: "Uptime", key: "Uptime" },
  { label: "Software Name", key: "description" },
  { label: "Status", key: "Status",
  render: (value) => (
    <span
      className={`rounded-md w-16 p-1 text-center text-white ${
        value === "Running"
          ? "bg-green-500"
                : "bg-red-500"
      }`}
    >
      {value ? value : "Stopped"}
    </span>
  ),
   },
];
